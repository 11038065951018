import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"


const get_ListPosition = (params, successCallabck, errorCallback) => {
  apiClient(true).get(API.JABATAN.LIST, params).then(resp => {
      if (resp.status === 200) {
          successCallabck(resp.data)
      }else {
          errorCallback(resp.data)
      }
  }).catch(err => {
      errorCallback(err.data)
  })
}

const post_SavePosition = (params, successCallabck, errorCallback) => {
  apiClient(true).post(API.JABATAN.SAVE, params).then(resp => {
      if (resp.status === 200 || resp.status === 201) {
          successCallabck(resp.data)
      }else {
          errorCallback(resp.data)
      }
  }).catch(err => {
      errorCallback(err.data)
  })
}

const get_PositionCheck = (params, successCallabck, errorCallback) => {
    apiClient(true).get(API.JABATAN.CHECK, params).then(resp => {
        if (resp.status === 200) {
            successCallabck(resp.data)
        }else {
            errorCallback(resp.data)
        }
    }).catch(err => {
        errorCallback(err.data)
    })
  }

export {
  get_ListPosition,
  post_SavePosition,
  get_PositionCheck
}