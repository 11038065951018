<template>
  <div class="info-detail p-0">
    <div class="row">
      <div class="col-12">
        <div class="title">Hak Akses</div>
        <hr />
      </div>
    </div>

    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          id="pills-modul-tab"
          data-toggle="pill"
          href="#pills-modul"
          role="tab"
          aria-controls="pills-modul"
          aria-selected="true"
          >Modul</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="pills-departement-tab"
          data-toggle="pill"
          href="#pills-departement"
          role="tab"
          aria-controls="pills-departement"
          aria-selected="false"
          >Departemen</a
        >
      </li>
    </ul>
    <div class="tab-content mt-5" id="pills-tabContent-access">
      <div
        class="tab-pane fade show active"
        id="pills-modul"
        role="tabpanel"
        aria-labelledby="pills-modul-tab"
      >
        <div class="row">
          <div class="col-8">
            <div
              class="form-check"
              style="display: flex; padding-left: 0; align-items: center"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :value="true"
                v-model="hak_akses"
                @change="setAccess"
                :disabled="validated == 1"
                id="accessEmployee"
              />
              <label class="form-check-label pl-4" for="accessEmployee">
                Berikan akses kepada karyawan
              </label>
            </div>
          </div>
          <div class="col-2 text-center">Dashboard</div>
          <div class="col-2 text-center">Aplikasi</div>
        </div>

        <div class="accordion mt-4" id="accordionExample">
          <div class="mt-2" v-for="(value, index) in dataRole" :key="index">
            <div class="header" :id="'headCollapse-' + index">
              <div class="row">
                <div class="col-8">
                  <button
                    class="btn btn-link btn-block text-left px-0"
                    type="button"
                    @click="collapseAll = !collapseAll"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {{ value.title }}
                  </button>
                </div>
                <div
                  class="col-2 text-center"
                  v-if="
                    value.webAll === true ||
                    value.webAll === 'true' ||
                    value.webAll === false ||
                    value.webAll === 'false'
                  "
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="dataRole[index].webAll"
                      @change="setAllData(index, 'webAll')"
                      :disabled="!hak_akses"
                    />
                  </label>
                </div>
                <div
                  class="col-2 text-center"
                  v-if="
                    value.mobileAll === true ||
                    value.mobileAll === 'true' ||
                    value.mobileAll === false ||
                    value.mobileAll === 'false'
                  "
                >
                  <label>
                    <input
                      type="checkbox"
                      v-model="dataRole[index].mobileAll"
                      @change="setAllData(index, 'mobileAll')"
                      :disabled="!hak_akses"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div
              :id="'collapse-' + index"
              class="collapse"
              :class="{ show: collapseAll }"
              :aria-labelledby="'headCollapse-' + index"
              data-parent="#accordionExample"
            >
              <div
                class="card-body"
                style="padding-left: 24px; padding-right: 24px"
              >
                <div class="access-item">
                  <div class="row" v-for="(item, i) in value.detail" :key="i">
                    <div class="col-8">
                      <div class="pl-4">{{ item.akses }}</div>
                    </div>
                    <div
                      class="col-2 text-center"
                    >
                      <label class="" v-if="
                        item.web === true ||
                        item.web === 'true' ||
                        item.web === false ||
                        item.web === 'false'
                      " style="margin-bottom: 0px">
                        <input
                          type="checkbox"
                          v-model="dataRole[index].detail[i].web"
                          @change="setRoleData(index, 'web')"
                          :disabled="!hak_akses"
                        />
                      </label>
                    </div>
                    <div
                      class="col-2 text-center"
                    >
                      <label v-if="
                        item.mobile === true ||
                        item.mobile === 'true' ||
                        item.mobile === false ||
                        item.mobile === 'false'
                      " class="" style="margin-bottom: 0px">
                        <input
                          type="checkbox"
                          v-model="dataRole[index].detail[i].mobile"
                          @change="setRoleData(index, 'mobile')"
                          :disabled="!hak_akses"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-departement"
        role="tabpanel"
        aria-labelledby="pills-departement-tab"
      >
        <div
          class="form-check"
          style="display: flex; padding-left: 0; align-items: center"
        >
          <input
            class="form-check-input"
            type="checkbox"
            :value="true"
            v-model="aksesDepartement"
            @change="setAccessDepartement"
            :disabled="validated == 1"
            id="accessEmployeeDepartement"
          />
          <label class="form-check-label pl-4" for="accessEmployeeDepartement">
            Berikan akses kepada karyawan
          </label>
        </div>

        <div class="table-responsive mt-3">
          <table
            class="table table-bordered table-striped"
            id="tableClientCategory"
          >
            <!-- <thead>
              <tr>
                <th style="width: 95%"></th>
                <th style="width: 5%"></th>
              </tr>
            </thead> -->
            <tbody>
              <tr v-for="(value, index) in listDepartement" :key="index">
                <td>{{ value.text }}</td>
                <td class="text-center">
                  <label class="" style="margin-bottom: 0px">
                    <input
                      type="checkbox"
                      :value="value.id"
                      @change="checkDivisi(value.id)"
                      :checked="isCheckAccess(value.id)"
                      :disabled="!aksesDepartement"
                    />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ROLE } from "../../../constans/Url";
import $ from "jquery";
// import TableLite from 'vue3-table-lite'
export default {
  name: "DueAccess",
  components: {},
  props: {
    access: Boolean,
    listdata: Object,
    editable: Number,
    accessDivisi: Boolean,
    listAccessDivisi: Object,
    listDivisi: Object,
  },
  data() {
    return {
      listDepartement: this.$props.listDivisi,
      validated: this.$props.editable,
      hak_akses: this.$props.access ? this.$props.access : false,
      aksesDepartement: this.$props.accessDivisi
        ? this.$props.accessDivisi
        : false,
      aksesDepartementRaw: this.$props.listAccessDivisi
        ? this.$props.listAccessDivisi
        : [],
      collapseAll: false,
      dataRole: ROLE,
    };
  },

  mounted() {
    if (this.$props.listdata.length == 0) {
      this.dataRole = ROLE;
    } else {
      // console.log(ROLE, this.$props.listdata);
      // this.dataRole = { ...ROLE, ...this.$props.listdata };
      this.dataRole = this.compareObject(ROLE, this.$props.listdata);
    }

    setTimeout(() => {
      $("#pills-modul-tab").tab("show");
      $("#pills-modul").addClass("active show");
    }, 600);
  },

  methods: {
    compareObject(var1, var2) {
      for (const i in var1) {
        if (Object.hasOwnProperty.call(var1, i)) {
          const element = var1[i];
          if (var2[i] && var2[i].id === element.id) {
            var2[i].title = element.title
            for (const c in element.detail) {
              if (Object.hasOwnProperty.call(element.detail, c)) {
                const eld = element.detail[c];
                if (var2[i].detail[c] === undefined) {
                  var2[i].detail.push(eld);
                  var2[i].webAll = false;
                  var2[i].mobileAll = false;
                }
              }
            }
          } else {
            var2[i] = element;
          }
        }
      }
      return var2;
    },
    setRoleData(index, tipe) {
      var moduleAccess = this.dataRole[index];
      var all = true;
      for (const key in moduleAccess.detail) {
        if (Object.hasOwnProperty.call(moduleAccess.detail, key)) {
          const element = moduleAccess.detail[key];
          if (tipe === "web" && !element.web) {
            all = false;
          } else if (tipe == "mobile" && !element.mobile) {
            all = false;
          }
        }
      }
      if (tipe == "web") {
        this.dataRole[index].webAll = all;
      } else if (tipe == "mobile") {
        this.dataRole[index].mobileAll = all;
      }
      this.$emit("update-role", {
        tipe: "access",
        value: this.dataRole,
      });
    },

    setAllData(index, type) {
      var value = this.dataRole[index][type];
      for (const key in this.dataRole[index].detail) {
        if (Object.hasOwnProperty.call(this.dataRole[index].detail, key)) {
          if (type == "mobileAll" && this.dataRole[index].detail[key].mobile != undefined) {
            this.dataRole[index].detail[key].mobile = value;
          } else if (type != "mobileAll" && this.dataRole[index].detail[key].web != undefined) {
            this.dataRole[index].detail[key].web = value;
          }
        }
      }
      this.$emit("update-role", {
        tipe: "access",
        value: this.dataRole,
      });
    },

    setAccess() {
      this.collapseAll = this.hak_akses;
      this.$emit("update-access", {
        tipe: "access",
        value: this.hak_akses,
      });
    },
    isCheckAccess(id) {
      var key = this.aksesDepartementRaw.findIndex((el) => el == id);
      if (key >= 0) {
        return true;
      } else {
        return false;
      }
    },
    setAccessDepartement() {
      this.$emit("update-access", {
        tipe: "divisi",
        value: this.aksesDepartement,
      });
    },
    checkDivisi(id) {
      var key = this.aksesDepartementRaw.findIndex((el) => el == id);
      if (key >= 0) {
        this.aksesDepartementRaw.splice(key, 1);
      } else {
        this.aksesDepartementRaw.push(id);
      }
      this.$emit("update-role", {
        tipe: "divisi",
        value: this.aksesDepartementRaw,
      });
    },
  },

  watch: {
    access: function (newVal) {
      this.hak_akses = newVal;
    },
    editable: function (newVal) {
      this.validated = newVal;
    },

    listdata: function (newVal) {
      if (newVal && newVal.length > 0) {
        if (typeof newVal === "object") {
          this.dataRole = this.compareObject(ROLE, newVal);
        } else {
          var parse = JSON.parse(newVal);
          this.dataRole = this.compareObject(ROLE, parse);
        }
      } else {
        this.dataRole = ROLE;
      }
    },

    accessDivisi: function (newVal) {
      this.aksesDepartement = newVal;
    },
    listAccessDivisi: function (newVal) {
      this.aksesDepartementRaw = newVal;
    },
    listDivisi: function (newVal) {
      this.listDepartement = newVal;
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.header {
  background-color: #eceff1;
  padding: 10px 24px;
  border-radius: 5px;
}

.header button {
  color: #000;
  text-decoration: none;
  font-size: 17px;
  font-weight: 600;
}
.access-item {
  padding: 20px 0px 20px 10px;
  border-radius: 5px;
  position: relative;
  margin-top: 10px;
}

.title-item {
  font-weight: 600;
  font-size: 17px;
}

.all-check {
  position: absolute;
  left: 15px;
  top: 27px;
}

.table:not(.table-invoice) td {
  border-top: 1px solid #eceff1 !important;
}

.table-striped tbody tr[data-v-0bc73c28]:nth-of-type(odd) {
  background-color: #eceff1;
}
</style>
