import { API } from "../../constans/Url"
import { apiClient } from "../../helper"


const get_KaryawanList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.KARYAWAN.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_KaryawanSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.KARYAWAN.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_FreelanceSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.KARYAWAN.SAVE_FREELANCE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_KaryawanDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.KARYAWAN.GET, {id: id}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_KaryawanMasterStatus = (id_company, callbackSuccess, callbackError) => {
  apiClient(true).get(API.KARYAWAN.MASTER.STATUS_LIST, {id_company: id_company}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_KaryawanStatusPernikahan = (callbackSuccess, callbackError) => {
  apiClient(true).get(API.KARYAWAN.MASTER.STATUS_PERNIKAHAN).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_KaryawanTransferSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.KARYAWAN.TRANSFER.SAVE, params).then(res => {
    if (res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_KaryawanPendidikanSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.KARYAWAN.PENDIDIKAN.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_KaryawanPendidikanDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.KARYAWAN.PENDIDIKAN.GET, {id: id}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_KaryawanKeluaragaSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.KARYAWAN.KELUARGA.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_KaryawanKeluargaDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.KARYAWAN.KELUARGA.GET, {id: id}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_KaryawanResignSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.KARYAWAN.RESIGN, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_KaryawanDetail, get_KaryawanKeluargaDetail, get_KaryawanList, get_KaryawanMasterStatus, get_KaryawanPendidikanDetail, get_KaryawanStatusPernikahan,
  post_FreelanceSave, post_KaryawanKeluaragaSave, post_KaryawanPendidikanSave, post_KaryawanResignSave, post_KaryawanSave, post_KaryawanTransferSave
}
